<template>
  <div>
    <div ref="template" class="dz-preview dz-file-preview px-2 py-2 hidden">
      <div class="w-full py-2 px-2">
        <div class="dz-details flex justify-between items-center">
          <div>
            <div class="bulk dz-filename text-xs">
              <span data-dz-name></span>
            </div>
            <div class="dz-size text-xs" data-dz-size></div>
          </div>
          <div class="flex items-center space-x-2">
            <button
              v-if="!autoProcess"
              id="upload-button"
              class="action-button uppercase p-1 text-sm text-gray-400 rounded-full hover:bg-primary hover:text-primary-on focus:outline-none"
              type="button"
            >
              <svg
                class="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </button>
            <button
              class="action-button uppercase p-1 text-sm text-gray-400 rounded-full hover:bg-primary hover:text-primary-on focus:outline-none"
              data-dz-remove
              type="button"
            >
              <svg
                class="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="flex flex-col dz-progress bg-gray-200 rounded-sm invisible">
          <span
            class="dz-upload transition-all duration-500 bg-green-300 h-1 rounded-sm w-0"
            data-dz-uploadprogress
          ></span>
        </div>
      </div>
    </div>
    <div v-if="type === 'LINK'" :class="inputClasses" class="flex items-center mb-2">
      <input
        v-model="videoUrl"
        :placeholder="$t('orders.video_url')"
        readonly
        class="text-sm py-0 px-1 bg-transparent border-0 border-b border-transparent focus:border-primary focus:outline-none placeholder-gray-300 w-full"
      />
      <TertiaryButton
        square
        v-tooltip="{
          content: $t('orders.delete_video_info'),
          classes: 'automated-service-tooltip',
        }"
        @click="() => reset(true)"
      >
        <TrashIcon class="h-4 w-4" />
      </TertiaryButton>
    </div>
    <div v-if="type === 'UPLOAD'" class="flex space-x-2 items-center">
      <div
        ref="videoContainer"
        :class="dropzoneClasses"
        class="dropzone cursor-pointer video-container"
      >
        <div
          :class="{ 'w-full': hasButton }"
          class="dz-message flex justify-between px-2 pointer-events-none"
        >
          <div class="text-xs text-left">
            <span class="font-semibold">{{ $t("orders.drop_file_or_click") }}</span
            ><br />
            <span>({{ $t("orders.format") }}: MP4, MOV, WMV)</span>
          </div>
          <div v-if="hasButton">
            <TertiaryButton compact>
              {{ $t("forms.file_upload.btn") }}
            </TertiaryButton>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasError && errorMessage" class="dz-error-message text-xs text-red-300">
      <span data-dz-errormessage v-text="errorMessage"></span>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n"
import { mapState } from "vuex"
import { TrashIcon } from "@/components/icons"
import Dropzone from "dropzone"
import "dropzone/dist/min/dropzone.min.css"
import { successToast } from "@/helpers/toasts"
import httpClient from "@/helpers/http"
import TertiaryButton from "@/components/elements/buttons/TertiaryButton.vue"

export default {
  components: {
    TrashIcon,
    TertiaryButton,
  },
  props: {
    modelValue: { type: String, default: null },
    autoProcess: { type: Boolean, default: true },
    hasButton: { type: Boolean, default: false },
    dropzoneClasses: {
      type: String,
      default: "default-dropzone",
    },
    inputClasses: {
      type: String,
      default: "w-full",
    },
    showAlert: { type: Boolean, default: true },
  },
  emits: ["update:modelValue", "input"],
  setup() {
    Dropzone.autoDiscover = false

    const { t } = useI18n()
    return { t }
  },
  computed: {
    ...mapState("sharedOrder", ["videoUploadUrl"]),
    translations() {
      return {
        dictFileTooBig: this.t("orders.video_file_too_big", {
          filesize: "{{filesize}}",
          maxFilesize: "{{maxFilesize}}",
        }),
        dictInvalidFileType: this.t("orders.video_invalid_type"),
        dictResponseError: this.t("orders.video_response_error", {
          statusCode: "{{statusCode}}",
        }),
        dictCancelUploadConfirmation: this.t("orders.cancel_upload_confirmation"),
        dictUploadCanceled: this.t("orders.upload_canceled"),
      }
    },
  },
  data: () => ({
    videoUrl: null,
    type: "UPLOAD",
    videoFileName: null,
    dropzone: null,
    hasError: false,
    errorMessage: null,
    uploadStarted: false,
    uploadSuccessful: false,
  }),
  watch: {
    videoUrl: function (value) {
      if (value) {
        this.$emit("update:modelValue", value)
        this.$emit("input", value)
      }
    },
    videoFileName: function (value) {
      if (value) {
        this.videoUrl = value
        this.type = "LINK"
        this.$emit("update:modelValue", value)
        this.$emit("input", value)
      }
    },
    type: {
      immediate: true,
      handler: function (value) {
        this.$nextTick(() => {
          if (value === "UPLOAD") {
            this.initDropzone()
          } else {
            this.dropzone = null
          }
        })
      },
    },
    modelValue: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.videoUrl = this.modelValue
          this.type = "LINK"
        }
      },
    },
    uploadSuccessful(value) {
      if (value && this.showAlert) {
        successToast({ message: this.t("orders.excel_upload_success_message") })
      }
    },
  },
  methods: {
    toggleCheckoutButton(enable = false) {
      this.$store.commit("product/updatePendingUploads", { increment: enable })

      let btn = document.querySelector("#cartBtn")
      if (!btn) return

      let pending = Number(btn.dataset["uploading"]) || 0
      if (pending) {
        pending = enable ? pending - 1 : pending + 1
      } else {
        pending = enable ? 0 : 1
      }

      btn.dataset["uploading"] = pending
      btn.disabled = !!pending

      if (pending) {
        btn.classList.add("bg-gray-200")
      } else {
        btn.classList.remove("bg-gray-200")
      }
    },
    reset(clean = false) {
      if (clean) {
        this.type = "UPLOAD"
        if (this.dropzone) {
          this.dropzone.removeAllFiles()
        }
        this.$emit("update:modelValue", "")
        this.$emit("input", "")
      } else {
        this.type = "UPLOAD"
        if (this.dropzone) {
          this.dropzone.removeAllFiles()
        }
      }
    },
    uploadVideo() {
      if (this.uploadStarted || this.uploadSuccessful) return

      let file = this.dropzone.files[0]
      if (!file) return

      this.dropzone.element.querySelector(".dz-progress").classList.remove("invisible")
      this.dropzone.processFile(this.dropzone.files[0])
      this.uploadStarted = true
      this.toggleCheckoutButton()
    },
    initDropzone() {
      if (this.dropzone || !this.$refs.videoContainer) return
      this.dropzone = new Dropzone(this.$refs.videoContainer, {
        url: "/dummy-url",
        maxFiles: 1,
        acceptedFiles: "video/mp4,video/quicktime,video/x-ms-wmv",
        previewTemplate: this.$refs.template.innerHTML,
        autoProcessQueue: false,
        maxFilesize: 500,
        ...this.translations,
      })

      this.dropzone.on("addedfile", file => {
        if (this.autoProcess) {
          this.dropzone.element.querySelector(".dz-progress").classList.remove("invisible")
        }
        httpClient
          .post(this.videoUploadUrl || "/uploads/thank-you-video", {
            params: { type: file.type },
          })
          .then(({ data }) => {
            this.dropzone.options.url = data.attributes.action
            file.additionalData = data.additionalData

            let arr = file.name.split("."),
              ext = arr.length > 1 ? arr.pop() : ""
            file.upload.filename = `${data.filename}${ext ? "." + ext : ""}`

            if (this.autoProcess) {
              this.uploadVideo()
            }
          })
        this.hasError = false
        this.errorMessage = null
        this.uploadSuccessful = false
        this.uploadStarted = false
        !this.autoProcess &&
          this.dropzone.previewsContainer
            .querySelector("#upload-button")
            .addEventListener("click", () => {
              this.uploadVideo()
            })
      })

      this.dropzone.on("removedfile", (/*file*/) => {
        this.uploadSuccessful = false
        this.hasError = null
        this.errorMessage = null
      })

      this.dropzone.on("sending", (file, xhr, formData) => {
        xhr.timeout = 99999999

        // add the additional form data
        for (let field in file.additionalData) {
          formData.append(field, file.additionalData[field])
        }
      })

      this.dropzone.on("error", (file, errorMessage) => {
        this.dropzone.removeAllFiles()
        this.hasError = true
        this.errorMessage = errorMessage
      })

      this.dropzone.on("complete", (/*file, response*/) => {
        this.uploadStarted = false
        this.toggleCheckoutButton(true)
      })

      this.dropzone.on("success", (file /*response*/) => {
        !this.autoProcess && this.dropzone.element.querySelector("#upload-button").remove()
        this.uploadSuccessful = true
        this.videoFileName = file.upload.filename
      })
    },
  },
}
</script>
